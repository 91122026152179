body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.tableListForm .ant-row .ant-form-item {
  display: flex;
}
.tableListForm .ant-form-item {
  margin-bottom: 24px !important;
  margin-right: 0;
  width: 90%;
}
.tableListForm .ant-form-item > .ant-form-item-label {
  width: auto;
  line-height: 32px;
  padding-right: 8px;
}
.tableListForm .ant-form-item .ant-form-item-control {
  line-height: 32px;
}
.tableListForm .ant-form-item-control-wrapper {
  flex: 1 1;
}
.form_span {
  display: inline-block;
  width: 56px;
  text-align: center;
}
.selectColor .ant-select-selection {
  text-align: center;
  background-color: #ffffff !important;
  border: 1px solid #d9d9d9 !important;
  border-right-color: transparent;
  width: 130px;
}
.editmodal .ant-form-item {
  margin-bottom: 18px;
}
.editmodal .ant-form-item-children {
  position: unset;
}
.tipText {
  position: absolute;
  width: 100%;
  bottom: -28px;
  left: 0px;
  font-size: 12px;
  color: #C1C1C1;
}
ul,
li {
  list-style: none;
  padding: 0px !important;
  margin: 0px !important;
}
